import React, { useState } from "react"
import { graphql } from "gatsby"
import { nanoid } from "nanoid"

import { Container, Section } from "../../anti/grid/grid"

import { AcfImage } from "./../acf-image"
import { CoreList } from "./../core-list"
import { CoreHeading } from "./../core-heading"
import { CoreParagraph } from "./../core-paragraph"

export const Fragment_CoreGroup = graphql`
  fragment CoreGroup on WordPress_CoreGroupBlock {
    attributes {
      ... on WordPress_CoreGroupBlockAttributes {
        align
        anchor
        className
      }
    }
    innerBlocks {
      name
      ...AcfImage
      ...CoreHeading
      ...CoreList
      ...CoreParagraph
    }
  }
`

export const CoreGroup = ({ data }) => {
  const [id] = useState(nanoid)
  const align = () => {
    if (data.attributes.align) {
      return `text-${data.attributes.align}`
    } else {
      return ""
    }
  }
  return (
    <Section
      className={`core-group ${align()} ${data.attributes.className || ""}`}
      id={`${data.attributes.anchor || ""}`}
    >
      <Container>
        <div className="core-group-body">
          {data.innerBlocks.map((value, i) => {
            switch (value.name) {
              case "acf/image":
                return <AcfImage data={value} key={`${id}${i}`} />
              case "core/list":
                return <CoreList data={value} key={`${id}${i}`} />
              case "core/heading":
                return <CoreHeading data={value} key={`${id}${i}`} />
              case "core/paragraph":
                return <CoreParagraph data={value} key={`${id}${i}`} />
              default:
                return <div />
            }
          })}
        </div>
      </Container>
    </Section>
  )
}
