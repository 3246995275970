import React, { useState, useContext } from "react"
import { nanoid } from "nanoid"
// import moment from "moment"

import Layout from "components/layout"
import SEO from "components/seo"

// import { Link } from "components/anti/link/link"
import { Section, Container } from "components/anti/grid/grid"
import { Cover } from "components/anti/cover/cover"

import { AcfImage } from "components/gutenberg/acf-image"
import { CoreGroup } from "components/gutenberg/core-group"
import { CoreHeading } from "components/gutenberg/core-heading"
import { CoreList } from "components/gutenberg/core-list"
import { CoreParagraph } from "components/gutenberg/core-paragraph"
// import { CoreShortcode } from "components/shortcode"

//Load Context
import { LangContext } from "../context/lang-context"

import titleCover from "../assets/img/common/bg-bg-header-gray.png"
import gfxLeft from "../assets/img/common/shift-graphic/img-graphic-header-left.png"
import gfxRight from "../assets/img/common/shift-graphic/img-graphic-header-right.png"

const PrivacyPolicyTemplate = ({ pageContext }) => {
  const [id] = useState(nanoid)
  const { lang } = useContext(LangContext)

  const data =
    lang === "EN"
      ? pageContext?.data
      : pageContext?.data?.translation || pageContext?.data

  const blocks = data?.blocks
  const title = data?.title

  // useLayoutEffect(() => {
  //   // ACF Cover Main conditional
  //   const navbar = document.querySelector(".navbar")
  //   if (blocks && blocks[0] && blocks[0].name !== "acf/cover-main") {
  //     navbar.classList.remove("transparent")
  //     navbar.classList.replace("dark", "light")
  //   }
  // }, [blocks])

  return (
    <Layout>
      <SEO
        title="Privacy Policy"
        // description={data.seo.opengraphDescription} // Experiment
        // image={data.seo.opengraphImage.sourceUrl} // Experiment
      />
      {/* <Card
        variant="boxless"
        img={titleCover}
        imgHeight="h-300px w-100"
        titleClassName="privacy-policy-title h1"
        title={title}
      /> */}
      <Cover
        variant="basic"
        img={titleCover}
        imgHeight="h-300px w-100"
        title={title}
        titleClassName={`privacy-policy-title`}
        contentMaxWidth="w-100"
      >
        <div className="d-md-block d-none">
          <img src={gfxLeft} className="gfx-left" />
          <img src={gfxRight} className="gfx-right" />
        </div>
      </Cover>
      <Section className="pt-5">
        <Container className="mw-lg">
          <div className="post-content">
            {blocks &&
              blocks.map((block, i) => {
                switch (block.name) {
                  case "acf/image":
                    return <AcfImage data={block} key={`${id}${i}`} /> // Meant to be used to replace Core Image
                  case "core/group":
                    return <CoreGroup data={block} key={`${id}${i}`} />
                  case "core/heading":
                    return <CoreHeading data={block} key={`${id}${i}`} />
                  case "core/list":
                    return <CoreList data={block} key={`${id}${i}`} />
                  case "core/paragraph":
                    return <CoreParagraph data={block} key={`${id}${i}`} />
                  // case "core/shortcode":
                  //   // prettier-ignore
                  //   return <CoreShortcode data={block} pageContext={pageContext} key={`${id}${i}`} />
                  default:
                    return <div key={`${id}${i}`} />
                }
              })}
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default PrivacyPolicyTemplate
